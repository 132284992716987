import React from 'react';
import styles from './html-content.module.scss';
import './html-content.scss';

const HtmlContent = props => {
  const { content, className, components } = props;
  return (
    // eslint-disable-next-line react/no-danger
    <div
      className={`${styles.wrapper} ${className}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default HtmlContent;
