import React from 'react';
import Img from 'gatsby-image';
import styles from './hero.module.scss';

const Hero = ({ className, title, bgImg }) => (
  <section className={`${styles.wrapper} ${className}`}>
    <div className={styles.background}>
      <Img fluid={bgImg} />
    </div>
    <div className={styles.content}>
      <h1 className="heading center">{title}</h1>
    </div>
  </section>
);

export default Hero;
